exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-become-a-partner-thank-you-tsx": () => import("./../../../src/pages/become-a-partner/thank-you.tsx" /* webpackChunkName: "component---src-pages-become-a-partner-thank-you-tsx" */),
  "component---src-pages-get-options-thank-you-tsx": () => import("./../../../src/pages/get-options/thank-you.tsx" /* webpackChunkName: "component---src-pages-get-options-thank-you-tsx" */),
  "component---src-pages-get-options-tsx": () => import("./../../../src/pages/get-options.tsx" /* webpackChunkName: "component---src-pages-get-options-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-pro-assisted-living-tsx": () => import("./../../../src/pages/pro/assisted-living.tsx" /* webpackChunkName: "component---src-pages-pro-assisted-living-tsx" */),
  "component---src-pages-pro-financial-advisors-tsx": () => import("./../../../src/pages/pro/financial-advisors.tsx" /* webpackChunkName: "component---src-pages-pro-financial-advisors-tsx" */),
  "component---src-pages-pro-home-care-tsx": () => import("./../../../src/pages/pro/home-care.tsx" /* webpackChunkName: "component---src-pages-pro-home-care-tsx" */),
  "component---src-pages-refer-thank-you-tsx": () => import("./../../../src/pages/refer/thank-you.tsx" /* webpackChunkName: "component---src-pages-refer-thank-you-tsx" */),
  "component---src-pages-refer-tsx": () => import("./../../../src/pages/refer.tsx" /* webpackChunkName: "component---src-pages-refer-tsx" */),
  "component---src-pages-talk-to-us-tsx": () => import("./../../../src/pages/talk-to-us.tsx" /* webpackChunkName: "component---src-pages-talk-to-us-tsx" */)
}

